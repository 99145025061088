<template>
    <b-modal
      v-model="data.showModal"
      button-size="sm"
      modal-class="recipe-modal"
      title-class="w-100"
      footer-class="w-100"
      no-fade
      @show="onShow"
      size="lg"
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div class="w-50">
          <color-button
            v-if="color"
            :color="color"
            hideRecipeButton
          />
        </div>
      </div>

      <!-- Modal content -->
      <div v-if="loadingCount > 0">
        Loading..
      </div>
      <div v-else-if="recipe" class="d-flex flex-column gap-4">
        <div class="d-flex gap-2">
          <b-btn
            variant="primary"
            size="sm"
            @click="calculateAmountWax(1000)"
          >
            Calculate for 1 kg of wax
          </b-btn>

          <b-btn
            variant="primary"
            size="sm"
            @click="calculateMinWax()"
          >
            Calculate for min wax
          </b-btn>

          <b-btn
            variant="primary"
            size="sm"
            @click="calculateAmountGrams(10)"
          >
            Calculate for 10 g of color
          </b-btn>
        </div>

        <b-card no-body>
          <b-tabs pills card>
            <b-tab title="French Color" active>
              <b-card-text>
                <div v-if="recipe.french" class="flex flex-col gap-4">
                  <div class="flex gap-12">
                    <div>
                      <table class="recipe-table">
                        <thead>
                          <tr>
                            <th colspan="2">Color</th>
                            <th class="text-right text-nowrap">Amount (%)</th>
                            <th class="text-right text-nowrap">Amount (g)</th>
                            <th class="text-right text-nowrap">Amount (g ≈)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><span class="color-box"></span></td>
                            <td><div class="color-id">% in Wax:</div></td>
                            <td class="text-right">
                              {{ recipe.french.in_wax | formatRecipe }} %
                            </td>
                            <td class="text-right">
                              {{ calculateTotalDyeAmount('french', false) | formatRecipe }} g
                            </td>

                            <td class="text-right">
                              {{ calculateTotalDyeAmount('french', true) | formatRecipe }} g
                            </td>
                          </tr>

                          <tr
                            v-for="color in colors.french"
                            :key="color.value"
                            v-show="hideEmpty ? recipe.french[color.value] > 0 : true"
                          >
                            <td><span class="color-box" :class="color.value"></span></td>
                            <td><div class="color-id">
                              <span v-if="color.corob_index">
                                ({{ color.corob_index.toString().padStart(2, '0') }})
                              </span>
                              {{ color.text }}:
                            </div></td>
                            <td class="text-right">{{ recipe.french[color.value] | format }} %</td>
                            <td class="text-right">
                              <span v-if="amountGramsNumber">
                                {{
                                  calculateDyeAmount(
                                    amountGrams,
                                    recipe.french[color.value],
                                    recipe.french.in_wax
                                  ) | formatRecipe
                                }} g
                              </span>
                            </td>

                            <td class="text-right">
                              <span v-if="amountGramsNumber">
                                {{
                                  calculateDyeAmount(
                                    amountGrams,
                                    recipe.french[color.value],
                                    recipe.french.in_wax,
                                    true
                                  ) | formatRecipe
                                }} g
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <table class="recipe-table">
                        <thead>
                          <tr>
                            <th class="text-right">Wax (g)</th>
                            <th class="text-right">Color (g)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="waxAmount in waxAmounts" :key="`waxAmount_${waxAmount}`">
                            <td class="text-right">{{ waxAmount | format }} g</td>
                            <td class="text-right">
                              <three-decimal-grammage
                                :grammage="calculateDyeAmount(waxAmount, 100, recipe.french.in_wax)"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="text-nowrap">
                    Wax: <span class="font-bold">{{ color.wax_french | capitalize }}</span>
                  </div>
                </div>
                <div v-else>Unavailable</div>
              </b-card-text>
            </b-tab>

            <b-tab title="Bekro">
              <b-card-text>
                <table v-if="recipe.bekro" class="recipe-table">
                  <thead>
                    <tr>
                      <th colspan="2">Color</th>
                      <th class="text-right">Amount</th>
                      <th class="text-right">Amount (custom)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span class="color-box b12802_01_37"></span></td>
                      <td><div class="color-id">12802-01-37:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12802_01_37 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12802_01_37
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b11169_71_11"></span></td>
                      <td><div class="color-id">11169-71-11:</div></td>
                      <td class="text-right">{{ recipe.bekro.b11169_71_11 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b11169_71_11
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b11180_39_11"></span></td>
                      <td><div class="color-id">11180-39-11:</div></td>
                      <td class="text-right">{{ recipe.bekro.b11180_39_11 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b11180_39_11
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b11189_51_11"></span></td>
                      <td><div class="color-id">11189-51-11:</div></td>
                      <td class="text-right">{{ recipe.bekro.b11189_51_11 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b11189_51_11
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b11939_30_11"></span></td>
                      <td><div class="color-id">11939-30-11:</div></td>
                      <td class="text-right">{{ recipe.bekro.b11939_30_11 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b11939_30_11
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12001_50_16"></span></td>
                      <td><div class="color-id">12001-50-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12001_50_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12001_50_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12317_27_16"></span></td>
                      <td><div class="color-id">12317-27-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12317_27_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12317_27_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12609_10_16"></span></td>
                      <td><div class="color-id">12609-10-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12609_10_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12609_10_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12901_60_16"></span></td>
                      <td><div class="color-id">12901-60-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12901_60_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12901_60_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12904_70_16"></span></td>
                      <td><div class="color-id">12904-70-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12904_70_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12904_70_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12908_30_16"></span></td>
                      <td><div class="color-id">12908-30-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12908_30_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12908_30_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b12910_10_36"></span></td>
                      <td><div class="color-id">12910-10-36:</div></td>
                      <td class="text-right">{{ recipe.bekro.b12910_10_36 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b12910_10_36
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b14046_60_16"></span></td>
                      <td><div class="color-id">14046-60-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b14046_60_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b14046_60_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b15049_50_16"></span></td>
                      <td><div class="color-id">15049-50-16:</div></td>
                      <td class="text-right">{{ recipe.bekro.b15049_50_16 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b15049_50_16
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td><span class="color-box b15053_34_36"></span></td>
                      <td><div class="color-id">15053-34-36:</div></td>
                      <td class="text-right">{{ recipe.bekro.b15053_34_36 | format }} g</td>
                      <td class="text-right">
                        <span v-if="amountGramsNumber">
                          <span>
                            {{
                              recipe.bekro.b15053_34_36
                              / bekroTotalAmount
                              * amountGramsNumber
                              | format
                            }} g
                          </span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-else>Unavailable</div>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-100 d-flex gap-4 justify-content-between items-center">
        <div>
        </div>

        <div class="flex gap-8 items-center">
          <div>
            <b-form-checkbox
              v-model="hideEmpty"
            >
              Hide unused
            </b-form-checkbox>
          </div>

          <div class="d-flex gap-2 items-center">
            <span class="text-nowrap">Grams of wax:</span>
            <b-form-input
              v-model="amountGrams"
              placeholder="Amount (grams)"
            ></b-form-input>

          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
import { roundThree } from '@/helpers';

const ColorButton = () => import('@/components/design/ColorButton.vue');
const ThreeDecimalGrammage = () => import('@/components/design/ThreeDecimalGrammage.vue');

export default {
  name: 'RecipeModal',
  components: {
    ColorButton,
    ThreeDecimalGrammage,
  },
  props: ['data'],
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    bekroTotalAmount() {
      return (
        this.recipe
          ? Object.values(this.recipe.bekro).reduce((acc, val) => acc + val, 0)
          : null
      );
    },
    amountGramsNumber() {
      return this.toNumber(this.amountGrams);
    },
  },
  data() {
    return {
      loadingCount: 0,
      recipe: null,
      color: null,
      hideEmpty: true,
      amountGrams: '1000',
      colors: {
        french: [
          { corob_index: 1, text: 'D20002', value: 'red_20002' },
          { corob_index: 2, text: 'D20003', value: 'yellow_20003' },
          { corob_index: 3, text: 'D20004', value: 'blue_20004' },
          { corob_index: 4, text: 'D20005', value: 'black_20005' },
          { corob_index: 5, text: 'D20006', value: 'green_20006' },
          { corob_index: 6, text: 'D20008', value: 'brown_20008' },
          { corob_index: 7, text: 'D20009', value: 'orange_20009' },
          { corob_index: 8, text: 'D20010R', value: 'magenta_20010' },
          { corob_index: 9, text: 'LD24405', value: 'ld24405' },
          { corob_index: 10, text: 'D23311', value: 'd23311' },
        ],
      },
      waxAmounts: [500, 1000],
    };
  },
  methods: {
    roundThree(value) {
      return roundThree(value);
    },
    onShow() {
      this.fetchRecipe();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    fetchRecipe() {
      this.loadingCount++;
      this.$http
        .get(`/recipe/${this.data.color.id}`)
        .then((res) => {
          this.color = res.body.color;
          this.recipe = res.body.recipe;
          if (this.recipe.french !== null) {
            // this.calculateAmountGrams(10);
          }
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch recipe: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    calculateTotalDyeAmount(brand, roundResult) {
      const colors = Object.values(this.colors[brand]);
      const totalDyeAmount = colors.reduce((acc, color) => {
        const dyeAmount = this.calculateDyeAmount(
          this.amountGrams,
          this.recipe[brand][color.value],
          this.recipe[brand].in_wax,
          roundResult,
        );
        return acc + dyeAmount;
      }, 0);
      return totalDyeAmount;
    },
    calculateDyeAmount(amountGrams, dyePercentage, inWaxPercentage, roundResult) {
      if (!amountGrams.toString().trim().length === 0) return null;
      amountGrams = this.toNumber(amountGrams);
      const totalDyeAmount = (amountGrams * inWaxPercentage) / 100;
      let dyeAmount = totalDyeAmount * (dyePercentage / 100);
      if (roundResult === true) {
        dyeAmount = +(Math.round(dyeAmount * 100) / 100).toFixed(2);
      }
      return dyeAmount;
    },
    calculateAmountGrams(dyeAmount) {
      const amountGrams = (dyeAmount / this.recipe.french.in_wax) * 100;
      this.amountGrams = Math.round(amountGrams / 1000) * 1000;
    },
    calculateAmountWax(amountWax) {
      this.amountGrams = amountWax;
    },
    calculateMinWax() {
      const minimumColorantWeight = 0.01;
      const colors = Object.values(this.colors.french);
      const minWax = Math.max(...colors.map((color) => {
        if (this.recipe.french[color.value] === 0) return 0;
        return Math.ceil(
          minimumColorantWeight
          / ((this.recipe.french.in_wax / 100) * (this.recipe.french[color.value] / 100)),
        );
      }));
      this.amountGrams = minWax;
    },
    toNumber(numberText) {
      const number = isNaN(parseInt(numberText, 10)) ? null : parseInt(numberText, 10);
      return number;
    },
  },
};
</script>

<style lang="scss" scoped>
.recipe-table {
  border-collapse: separate;
  border-spacing: 10px;
}

.color-box {
  margin-right: 10px;
}

.color-id {
  width: 110px;
}

</style>
